import styles from "../assets/styles";
import styled from "styled-components";
import { MainContainer } from "./Container";
import { Link } from "react-router-dom";
import "./css/MobileLanding.css";

export const Footer = () => {
  return (
    <Container className="footer">
      <MainContainer
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <FooterMain className="FooterMain">
          <MainText>Other More</MainText>
          <LinkWrap className="LinkWrap">
            <NavLink to="/" className="nav-link">
              About Me
            </NavLink>
            <NavLink to="/careerv2" className="nav-link">
              Career
            </NavLink>
            <NavLink to="/portfolio" className="nav-link">
              Portfolio
            </NavLink>
            <NavLink to="/contact" className="nav-link">
              Contact
            </NavLink>
          </LinkWrap>
          <i
            style={{
              alignSelf: "center",
              fontSize: 14,
              color: `${styles.ash}`,
            }}
          >
            c 2025, created from ReactJS
          </i>
        </FooterMain>
      </MainContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${styles.orange};
  display: flex;
  height: 213px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const FooterMain = styled.section`
  height: 124px;
  width: 90vw;
  background-color: ${styles.white};
  align-self: flex-end;
  margin-left: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-center: center;
  padding: 24px;
  border-radius: 16px 0 0 16px;
  flex-wrap: wrap;
`;

const MainText = styled.h1`
  font-size: 3rem;
  font-family: ${styles.black};
  align-self: flex-start;
  width: 150px;
  margin: 0px;
`;
const LinkWrap = styled.div`
  height: 100%;
  flex: 0.8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${styles.black};
  font-family: ${styles.medium};
  font-size: 1.5rem;
  text-transform: lowercase;
`;
export default Footer;
