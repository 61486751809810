import styles from "../assets/styles";
import * as Tag from "../components/Container";
import Navigation from "../components/Navigations";
import styled from "styled-components";
import Photo from "../assets/image/myphoto.png";
import { HeaderLanding } from "../components/HeaderLanding";
import Footer from "../components/Footer";
import { SkillSection } from "../components/SkillSection";
import { SectionTag } from "../components/SectionTag";
function Home() {
  const training = [
    {
      title: "TESDA NC3- JAVA Programming",
      company: "Joysis Tech-Voc Inc.",
      color: styles.cherry,
    },
    {
      title: "TESDA LVL II - Web Development",
      company: "RVN Tech-Voc Inc. ",
      color: styles.ash,
    },
    {
      title: "Advanced Front-End Development",
      company: "Bayan Academy Foundation",
      color: styles.orange,
    },
    {
      title: "Front-End Development",
      company: "Bayan Academy Foundation",
      color: styles.ash,
    },
    {
      title: "Web Development with React Beginners",
      company: "Ground Gurus",
      color: styles.cherry,
    },
    {
      title: "General Data Protection Regulation (GDPR) United Kingdom.",
      company: "Core Learning",
      color: styles.orange,
    },
  ];
  return (
    <>
      <Navigation backgroundColor={styles.black} />
      <HeaderLanding />
      <SkillSection />
      <EducationSection className="EducationSection">
        <Tag.MainContainer className="skill-main">
          <SectionTag title={"Education"} color={styles.cherry} />
          <SectionEducDetail className="SectionEducDetail">
            <DiplomaHolder className="DiplomaHolder">
              College Degree
            </DiplomaHolder>
            <CollgeName className="CollgeName">
              Cavite State University - Imus Cavite
            </CollgeName>
            <Coursename className="CourseName">
              Bachelor of Science in Computer Science
            </Coursename>
            <Batchname>with Held of</Batchname>
            <GWA>1.75 GWA</GWA>
          </SectionEducDetail>
          <MyPhoto className="MyPhotos" src={Photo} />
        </Tag.MainContainer>
      </EducationSection>
      <ProfesionalSection className="ProfSection">
        <MainContainer className="skill-main">
          <SectionTag title={"Training"} color={styles.orange} />
          <TrainingBOx className="TrainingBOx">
            {training.map((training, i) => (
              <TrainingList key={i} className="TrainingList" color={training.color}>
                <TitleTraining>{training.title}</TitleTraining>
                <NameTraining>{training.company}</NameTraining>
              </TrainingList>
            ))}
          </TrainingBOx>
        </MainContainer>
      </ProfesionalSection>

      <SpaceSection className="SpaceSection">
        <Tag.MainContainer className="row">
          <h1
            className="attain"
            style={{
              alignSelf: "flex-start",
              fontFamily: `${styles.bold}`,
              fontSize: "5.5rem",
              color: `${styles.cherry}`,
              transform: "rotate(-2deg)",
              padding: "10px 42px",
              backgroundColor: "#FFD2D8",
            }}
          >
            Attainment
          </h1>
          <p
            className="qoute"
            style={{
              textAlign: "center",
              fontFamily: `${styles.medium}`,
              fontSize: 20,
            }}
          >
            Gained valuable experience in developments through working with an
            international clients
            <br />
            United Kingdom, USA, Singapore and Philippines.
          </p>
        </Tag.MainContainer>
      </SpaceSection>

      <Footer />
    </>
  );
}

const SpaceSection = styled.section`
  height: auto;
  background-color: ${styles.white};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 24px 6%;
`;
const EducationSection = styled.section`
  padding: 2.5% 3% 2.5% 0px;
  height: auto;
  background-color: ${styles.ash};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MyPhoto = styled.img`
  object-fit: contain;
`;
const SectionEducDetail = styled.section`
  display: flex;
  margin-left: -89px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  gap: 10px;
`;
const DiplomaHolder = styled.span`
  font-family: ${styles.black};
  font-size: 2.625rem;
  color: ${styles.white};
  text-transform: uppercase;
`;
const CollgeName = styled.span`
  font-family: ${styles.medium};
  font-size: 2.063rem;
  margin-top: 21px;
  color: ${styles.white};
`;
const Coursename = styled.span`
  font-family: ${styles.bold};
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 12px;
  color: ${styles.white};
`;
const Batchname = styled.span`
  font-family: ${styles.bold};
  font-weight: 500;
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 21px;
  color: ${styles.white};
`;
const GWA = styled.span`
  font-family: ${styles.bold};
  font-weight: 500;
  font-size: 1.5rem;
  color: ${styles.white};
`;

const ProfesionalSection = styled.section`
  height: 554.04px;
  background-color: ${styles.dark};
  display: flex;
  justify-content: center;
  align-content: center;
`;
export const MainContainer = styled.main`
  max-width: 1537px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 0%;
  margin: 0px;
  background-color: transparent;
`;

const TrainingBOx = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  flex-direction: row;
  margin-left: -50px;
  flex-wrap: wrap;
`;
const TrainingList = styled.div`
  display: flex;
  width: 270px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 35px;
  border: 4px solid white;
  border-radius: 8px;
  background-color: ${(props) => props.color};
`;
const TitleTraining = styled.span`
  font-size: 1.1rem;
  color: ${styles.white};
  font-family: ${styles.bold};
`;
const NameTraining = styled.span`
  font-size: 1rem;
  margin-top: 14px;
  color: ${styles.white};
  font-family: ${styles.bold};
`;

export default Home;
