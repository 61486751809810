import moby from "../project/Entry Screen1.png";
import mobyweb from "../project/mobylzeweb.jpg";
import car from "../project/car3.png";
import happy from "../project/payment.png";
import eshow from "../project/eshow1.jpg";

import tool from "../project/13.jpg";
import tool1 from "../project/Image7.png";

//Company Logo
import iamCeb from "../assets/image/iamceb.png";
import rooche from "../assets/image/roochelogo.png";
import claymind from "../assets/image/claymindlogo.png";
import topnotch from "../assets/image/topnotchlogo.jpg";

//Portfolio

// Prayer Partner
import prayer1 from "../assets/image/prayerpartner.jpeg";
import prayer2 from "../assets/image/prayerpartner1.jpeg";
import prayer3 from "../assets/image/prayerpartner2.jpeg";

// Pilon
import pilon0 from "../project/pilon.png";

// Survey
import survey2 from "../project/survey2.png";

//Wolly
import wolly3 from "../project/Filter Tab.png";

//Theis
import styles from "../../2024/assets/styles";

//bg color
// #FFDF1E
// #EC243E
// #F7941D
// #FFB400

// app : 1 = mobile, 2 = web, 3 ux
/// com = company 1
export const careerData = [
  {
    logo: iamCeb,
    backgroundColor: "#FFDF1E",
    title: "FullStack Developer",
    company: "Cebu Pacific Air",
    location: "Pasay, Philippines",
    datestart: "June 18, 2024",
    dateend: "",
    worktype: "Fulltime",
    role: [
      `Collaborate with Cebu Pacific Air, one of the leading airlines in the Philippines, to manage significant responsibilities in developing and enhancing their internal business systems.`,
      `Collaborating with a corporate team with a high hierarchy during the development operations.`,
      `Migrating legacy SharePoint 2010 systems to modern records, improving data accessibility and system efficiency.`,
      `Tools – React Native Expo, Postman, Angular Module, Aspx, RESTful, AZURE DevOps, Jotai, Apigee, Gits.`,
    ],
    project: [
      {
        title: "IAmCeb V4",
        color: styles.cherry,
        image: iamCeb,
        type: "Business Internal System consiste of more than 74 sites",
        mobile: false,
      },
    ],
  },
  {
    logo: claymind,
    backgroundColor: "#F7941D",
    title: "React Native Developer",
    company: "Claymind IT",
    location: "Sandimas, USA",
    datestart: "April 18, 2024",
    dateend: "May 23, 2024",
    worktype: "Partime",
    role: [
      `Responsible for solving program issues from users' remarks and maintaining the application in the latest version.`,
      `Responsible for adding a new feature to the application for significant product improvement.`,
      `Coordinating the progress to the team lead and submitting for GitHub pull request.`,
      `React Native TS, Redux, and Google Cloud Integration (MERN) technologies were used.`,
      `Using JIRA, GitHub, Slacks, and Excel for Task and Collaboration Tools during operation`,
    ],
    project: [
      {
        title: "Prayer Partner App",
        color: styles.cherry,
        image: prayer1,
        type: "Dashboard - Screen",
        mobile: true,
      },
      {
        title: "Prayer Partner App",
        color: styles.orange,
        image: prayer2,
        type: "Request Prayer Notif - Screen",
        mobile: true,
      },
      {
        title: "Prayer Partner App",
        color: styles.cherry,
        image: prayer3,
        type: "Verse of the Day - Screen",
        mobile: true,
      },
    ],
  },
  {
    logo: rooche,
    backgroundColor: "#EC243E",
    title: "Web and Mobile Developer",
    company: "Rooche Digital IT",
    location: "Quezon C, Philippines",
    datestart: "April 25, 2022",
    dateend: "April 26,2024",
    worktype: "Fulltime",
    role: [
      `Developing the application user interface based on the Design given by the UI/UX.`,
      `Developing the functionalities based on the task assigned by the Project Manager. Responsible for solving program issues from users' remarks and maintaining the application in the latest version.`,
      `Outsourcing the demand of clients from the USA, United Kingdom, Philippines, and
        Singapore.`,
      `The technology used React, React Native and Other Libraries for development and API for database connection for Web Development Projects.`,
      `HANDLED and developed a bank and finance mobile project.`,
      `Responsible for deploying the mobile in Google, APPLE Store, and Live in Website.`,
    ],
    project: [
      {
        title: "Pilon Mobile",
        color: styles.cherry,
        image: pilon0,
        type: "Bank Financing Application",
        mobile: true,
      },
      {
        title: "Moblyze",
        color: styles.orange,
        image: moby,
        type: "Find Job for Industrial Engineering",
        mobile: true,
      },
      {
        title: "Mobylyze Website",
        color: styles.cherry,
        image: mobyweb,
        type: "Movie/Theater for Rent App",
        mobile: false,
      },
      {
        title: "Surveyor",
        color: styles.cherry,
        image: survey2,
        type: "Location Services Application",
        mobile: true,
      },
      {
        title: "Car Rental",
        color: styles.cherry,
        image: car,
        type: "Car Monitoring App",
        mobile: true,
      },
      {
        title: "Wolly Bugger",
        color: styles.cherry,
        image: wolly3,
        type: "Fishing Site locator",
        mobile: true,
      },
      {
        title: "Eshow Mobile",
        color: styles.cherry,
        image: eshow,
        type: "Movie/Theater for Rent App",
        mobile: true,
      },

      {
        title: "Happy Talk",
        color: styles.orange,
        image: happy,
        type: "Social App",
        mobile: false,
      },
    ],
  },
  {
    logo: topnotch,
    backgroundColor: "#FFB400",
    title: "Web & Application Developer",
    company: "Top Notch Const.",
    location: "Cavite, Philippines",
    datestart: "Aug 25, 2021",
    dateend: "Nov 25, 2021",
    worktype: "Trainee",
    role: [
      `Responsible for Designs, Front End Development using Adobe XD, HTML, CSS with
Bootstrap, JavaScript (DOM, jQuery), ASP.NET MVC Core and SQL as Backend.`,
    ],
    project: [
      {
        title: "Tool Rent",
        color: styles.cherry,
        image: tool,
        type: "Warehouse tool and Inventory System",
        mobile: false,
      },
      {
        title: "Tool Rent",
        color: styles.cherry,
        image: tool1,
        type: "Warehouse tool - Login Page",
        mobile: false,
      },
    ],
  },
];
