import styled from "styled-components";
import styles from "../assets/styles";
import Navigation from "../components/Navigations";
import icChevron from "../assets/image/chevron-left.svg";
import icChevronRight from "../assets/image/chevron-right.svg";
import { SectionTag } from "../components/SectionTag";
import { useCallback, useEffect, useState } from "react";
import Footer from "../components/Footer";
import { ProjectCard } from "../components/ProjectCard";
import { careerData as _careerData } from "./projectdata";

function CareerNew() {
  const [page, setpage] = useState(_careerData[0]);
  const [pageNO, setPageNO] = useState(0);
  const length = Object.keys(_careerData).length - 1;

  useEffect(() => {
    setpage(_careerData[pageNO]);
  }, [pageNO]);

  const prevMobilePreview = useCallback(() => {
    if (pageNO <= 0) {
      setPageNO(length);
    } else {
      setPageNO(pageNO - 1);
    }
  }, [pageNO, length]);

  const nextPagePreview = useCallback(() => {
    if (pageNO >= length) {
      setPageNO(0);
    } else {
      setPageNO(pageNO + 1);
    }
  }, [pageNO, length]);

  return (
    <>
      <Navigation backgroundColor={page.backgroundColor} />
      <HeadingContainer color={page.backgroundColor}>
        <MainContainer className="MainContainer">
          <Arrow onClick={prevMobilePreview} className="Arrow">
            <img className="chevronl" src={icChevron} alt="chevronleft" />
            <p
              style={{
                fontFamily: styles.medium,
                fontSize: 16,
                color: styles.dark,
                paddingLeft: 12,
              }}
            >
              {_careerData[pageNO === 0 ? length : pageNO - 1]?.company}
            </p>
          </Arrow>
          <CompanyImg
            src={page?.logo}
            alt={page?.company}
            className="CareerCorpImg"
          />
          <Arrow onClick={nextPagePreview} className="ArrowRight">
            <p
              style={{
                fontFamily: styles.medium,
                fontSize: 16,
                color: styles.dark,
                paddingRight: 12,
              }}
            >
              {_careerData[pageNO >= length ? 0 : pageNO + 1]?.company}
            </p>
            <img className="chevronl" src={icChevronRight} alt="chevronleft" />
          </Arrow>
        </MainContainer>
      </HeadingContainer>
      <CareerSection>
        <MainContainer className="CareerSection">
          <SectionTag title={"Career"} color={styles.cherry} />
          <DetailSection>
            <TitleBox className="TitleBox">
              <TitleLeft className="TitleLeft">
                <LabelName className="LabelName">{page?.title}</LabelName>
                <LabelDate>
                  {page?.datestart} - {page?.dateend || "Present"}
                </LabelDate>
              </TitleLeft>
              <TitleRight className="TitleLeft">
                <LblJob className="LblJob">
                  {page?.company} | {page?.location}
                </LblJob>
                <LabelDate
                  className="LblJob"
                  style={{
                    fontSize: "1.125rem",
                    textTransform: "uppercase",
                    padding: "10px",
                  }}
                >
                  {page?.worktype}
                </LabelDate>
              </TitleRight>
            </TitleBox>
            <JobUL>
              {page?.role &&
                page?.role.map((role, index) => (
                  <JobLI key={index} className="Li">
                    {role}
                  </JobLI>
                ))}
            </JobUL>
          </DetailSection>
        </MainContainer>
      </CareerSection>
      <Project>
        <MainContainer className="MainContainer">
          <ProjectTitle className="ProjectTitle">Project Handled</ProjectTitle>
          <ApplicationBox className="ApplicationBox">
            {page.project &&
              page.project.map((project, i) => (
                <ProjectCard
                  key={i}
                  title={project?.title}
                  color={project?.color}
                  image={project?.image}
                  types={project?.type}
                  mobile={project?.mobile}
                />
              ))}
          </ApplicationBox>
        </MainContainer>
      </Project>
      <Footer />
    </>
  );
}

const HeadingContainer = styled.header`
  background-color: ${(props) => props.color};
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;
const MainContainer = styled.div`
  max-width: 1550px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: transparent;
`;

const CompanyImg = styled.img`
  height: 239px;
  width: 386px;
  object-fit: contain;
`;

const Arrow = styled.section`
  width: auto;
  display: flex;
  align-content: center;
  justify-content: space-between;
  cursor: pointer;
`;
const CareerSection = styled.section`
  min-height: 468px;
  height: auto;
  background-color: ${styles.dark};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2.5%;
  gap: 10px;
`;

const DetailSection = styled.section`
  flex: 1;
  min-height: 325px;
  height: 100%;
  padding: 0px 24px;
  transition: height 0.5s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 24px;
  margin-right: 2.5%;
`;

const TitleBox = styled.section`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const JobUL = styled.ul`
  height: auto;
  align-items: center;
  padding: 0px;
`;
const JobLI = styled.li`
  list-style: none;
  color: white;
  line-height: 22px;
  font-family: ${styles.medium};
  font-size: 1.5rem;
  text-align: justify;
  margin-bottom: 16px;
word-break: break-word;`;

const TitleLeft = styled.article`
  flex: 1;
  text-align: left;
`;
const TitleRight = styled.article`
  flex: 1;
  text-align: right;
`;
const LabelName = styled.h1`
  font-size: 2.313rem;
  color: ${styles.white};
  font-family: ${styles.heavy};
  margin: 0;
  padding: 0;
`;
const LabelDate = styled.p`
  font-size: 1rem;
  color: ${styles.white};
  font-family: ${styles.medium};
  margin: 0;
  padding: 0;
`;
const LblJob = styled.h5`
  font-size: 1.75rem;
  color: ${styles.white};
  font-family: ${styles.regular};
  margin: 0;
  padding: 0;
`;

// Projects
const Project = styled.section`
  height: auto;
  background-color: ${styles.gray};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 8% 120px;
`;
const ProjectTitle = styled.h1`
  width: 100%;
  color: ${styles.white};
  border: 4px solid ${styles.white};
  border-radius: 8px;
  padding: 28px 14px;
  font-family: ${styles.bold};
  background-color: ${styles.orange};
  font-size: 2.75rem;
  align-self: flex-start;
`;
const ApplicationBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
`;
export default CareerNew;
