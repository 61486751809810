import pilon1 from "../project/pilon.png";
import survey1 from "../project/survey1.png";
import moby from "../project/Entry Screen1.png";
import mobyweb from "../project/mobylzeweb.jpg";
import car from "../project/car3.png";
import fish from "../project/wollybuggers.png";
import happy from "../project/payment.png";
import eshow from "../project/eshow1.jpg";

import tool from "../project/13.jpg";
import tool1 from "../project/Image7.png";
import news from "../project/news1.jpg";

//Company Logo
import iamCeb from "../assets/image/iamceb.png";
import claymind from "../assets/image/claymindlogo.png"

//Portfolio
import web1 from "../project/1.png";
import web2 from "../project/2.png";
import web3 from "../project/3.png";
import web4 from "../project/4.png";
import web5 from "../project/5.jpg";
import web6 from "../project/6.png";
import web7 from "../project/7.png";
import web10 from "../project/10.jpg";
import web11 from "../project/11.png";
import web12 from "../project/12.png";
import web13 from "../project/13.jpg";
import web14 from "../project/Photo3.png";
import web15 from "../project/netflix.jpg";
import web16 from "../project/Cr2.png";
import web17 from "../project/Photo4.jpg";


// Pilon
import pilon0 from "../project/pilon.png";
import pilon2 from "../project/pilon2.jpg";
import pilon3 from "../project/pilon3.jpg";

// Survey
import survey2 from "../project/survey2.png";
import survey3 from "../project/survey3.png";

//Wolly
import wolly1 from "../project/wolly1.png";
import wolly2 from "../project/wollybuggers.png";
import wolly3 from "../project/Filter Tab.png";

//Mobylze
import moby1 from "../project/Entry Screen1.png";
import moby2 from "../project/Entry Screen2.png";
import moby3 from "../project/Entry Screen3.png";
import moby4 from "../project/My Profile.png";

// Car Rental
import carRent1 from "../project/car.png";
import carRent3 from "../project/car3.png";
import carRent4 from "../project/car1.png";

//Design 1

//Theis
import thes1 from "../project/thes1.png";
import thes2 from "../project/thes2.png";
import thes3 from "../project/thes3.png";
import thes4 from "../project/thes4.png";
import thes5 from "../project/thes5.png";
import styles from "../../assets/styles";


//bg color
// #FFDF1E
// #EC243E
// #F7941D
// #FFB400


export const _careerData = [
  {
    logo: iamCeb,
    backgroundColor: "#FFDF1E",
    title: "FullStack Developer",
        company: "Cebu Pacific Air",
    location: "Pasay, Philippines",
    datestart: "June 18, 2024",
    dateend: "",
    worktype: "Fulltime",
    role: [
      `Working in known and Top Airlines in the Philippines in exchange huge responsibility.`,
      `Collaborating with a corporate team with a high hierarchy during the development operations.`,
      `Responsible for the company's internal system using Microsoft SharePoint and Power Automation.`,
      `Write a front-end and back-end code using React Native and Node JS to develop the internal system for mobile application.`
    ],
    project: [
      {
        title: "IAmCeb V4",
        color: styles.cherry,
        image: iamCeb,
        type: "Sharepoint Migrateion",
        mobile: false,
      },
    ],
  },
  {
    logo: claymind,
    backgroundColor: "#F7941D",
    title: "FullStack Developer",
    company: "Rooche Digital Technology",
    location: "Pasay, Philippines",
    datestart: "June 18, 2024",
    dateend: "",
    worktype: "Fulltime",
    role: [
      `responsible for publishing in google and apple store.`,
      `providing a quality services in western countries and asia.`,
      `the technology used react and other libraries for
      development and api for database connection for web
      development projects.`,
    ],
    project: [
      {
        title: "Pilon Mobile",
        color: styles.cherry,
        image: iamCeb,
        type: "application web",
        mobile: true,
      },
    ],
  },
   {
    logo: iamCeb,
    backgroundColor: "#FFDF1E",
    title: "FullStack11 Developer",
    company: "Top Notch Cons",
    location: "Pasay, Philippines",
    datestart: "June 18, 2024",
    dateend: "",
    worktype: "Fulltime",
    role: [
      `lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu
      nt ut labore et dolore magna aliqua. ut enim ad minim veniam, quis nostrud exercitation
    ullamco laboris nisi ut aliquip ex ea commodo consequat.
    `,
      `responsible for publishing in google and apple store.`,
      `providing a quality services in western countries and asia.`,
      `the technology used react and other libraries for
      development and api for database connection for web
      development projects.`,
    ],
    project: [
      {
        title: "Pilon Mobile 3",
        color: styles.cherry,
        image: iamCeb,
        type: "application web",
        mobile: true,
      },
    ],
  },
];
export const _dataMobile = [
  {
    title: "Pilon Mobile",
    type: "Bank Financing Application ",
    description:
      "The solution to accelerate your payments and be in control of your cash flow. Our mobile app is designed to help Suppliers. Available in Apple and Google Store",
    tech: ["react native", "typesript", "biometrics"],
    image: [pilon0, pilon2, pilon3],
  },
  {
    title: "Moblyze",
    type: "Find Job for Industrial Engineering",
    description:
      "Talent marketplace for the net zero era, focussed primarily on the Energy & Industrial sectors.",
    tech: ["react native", "javascript", "api integration"],
    image: [moby3, moby1, moby4],
  },
  {
    title: "Surveyor",
    type: "Location Services Application",
    description:
      "Navigate a route, capture  images and videos of your journey in real time. Integrated the Google Map and Cloud data to upload caputured images.",
    tech: ["react native", "typescipt", "google map sdk"],
    image: [survey1, survey2, survey3],
  },
  {
    title: "Car Rental",
    type: "Car Monitoring App",
    description:
      "Real time Tracing and Tracking of the registered vehicles. Integrated from Google Maps. Allowed the owner to determine the location and display details of vehicle, some use  for shipment ",
    tech: ["kotlin", "google map sdk"],
    image: [carRent1, carRent3, carRent4],
  },
  {
    title: "Wolly Bugger",
    type: "Fishing Site locator",
    description:
      "Display the location of fish in the lake, river from different site and type of available fishes",
    tech: ["react native", "javascript", "google map sdk"],
    image: [wolly1, wolly3],
  },
  {
    title: "Eshow Mobile",
    type: "Movie/Theater for Rent App",
    description:
      "eShow Mobile App allows event managers to redefine an attendee’s experience and create deeper participation by allowing attendees to use their favorite mobile device to navigate the carefully planned event. Available in Apple and Play Store",
    tech: ["kotlin", "sqlite"],
    image: [eshow],
  },
];

export const _dataWeb = [
  {
    title: "Mobylyze Website",
    type: "Admin Industrial MarketPlace",
    description:
      "Allow to monitor and to post the job posting for the career opening/s.",
    tech: ["react", "javascript", "api integration"],
    image: [mobyweb],
  },
  {
    title: "Logo for Job",
    type: "Job Placement System",
    description:
      "Job Placement Posting system with applicant validation form and examination system. College Project",
    tech: ["php", "bootstrap", "mysql"],
    image: [web16, web11, web12],
  },
  {
    title: "Tool Materials",
    type: "Tool Management and Inventory System",
    description:
      "Web Application System for Construction Company to Monitory the warehouse tool and inventory delivered for the opeation of the construction project, with add to cart operation.",
    tech: ["asp.net", "bootstrap", "My SQL Server"],
    image: [tool, tool1],
  },
  {
    title: "News Editors w/ Sentiment",
    type: "News Editor Tool with Sentiment Analyss NLP",
    description:
      "A web system for news writer with applied a Sentiment Analysis, that can collect a expresion from news, comments and generate a daya analytics.",
    tech: ["reactJS", "Google Authentication", "MYSQL/PHP"],
    image: [thes1, thes2, thes3, thes4, thes5],
  },
  {
    title: "My Works",
    type: "Personal Website for Self Study",
    description:
      "My Personal Works, based on self-study, bootcamp training, and College Activity.",
    tech: ["HTML/CSS", "JAVASCRIPT", "Bootstrap"],
    image: [web2, web1, web4, web5, web6, web7, web10, web3, web15, web17],
  },
];

export const _handledproject = [
  {
    title: "Surveyor Cyclops",
    type: "Google Map Service",
    start: false,
    img: survey1,
    a: "December 14 2023",
    b: "Present 2024",
    mobile: true,
    tech: "Mobile: React Native TS",
    show: true,
  },
  {
    title: "Pilon Bank Mobile",
    type: "Bank Financial",
    start: true,
    img: pilon1,
    a: "July 14 2023",
    b: "December 18 2023",
    mobile: true,
    tech: "Mobile: React Native TS / Biometrics",
    show: true,
  },
  {
    title: "RESIGNED FOR COLLEGE",
    type: "FInal Thesis and Graduationg Clearance",
    start: false,
    img: news,
    mobile: false,
    tech: "Web: React Js",
    show: false,
    a: "March 30, 2022",
    b: "July 24, 2023 ",
  },
  {
    title: "Car Rental",
    type: "Real Time Tracking Car",
    start: false,
    img: car,
    mobile: true,
    tech: "Mobile: KOTLIN",
    show: true,

    a: "October 15, 2022",
    b: "March 28, 2023 ",
  },

  {
    title: "Eshow Mobile",
    type: "E Learning Mobile App",
    start: false,
    img: eshow,
    mobile: true,
    show: true,

    a: "October 15, 2022",
    b: "December 1, 2022 ",
    tech: "Mobile: KOTLIN",
  },

  {
    title: "Happy Talk",
    type: "Social App",
    start: false,
    img: happy,
    mobile: false,
    show: true,

    a: "September 29, 2022",
    b: "October 15, 2022 ",
    tech: "Web: React JS",
  },
  {
    title: "Wolly Bugger",
    type: "Fishing Site Navigation",
    start: false,
    img: fish,
    mobile: true,
    show: true,

    a: "September 21, 2022",
    b: "November  28 2022",
    tech: "Mobile: React Native JS",
  },
  {
    title: "Moblyze Mobile",
    type: "Find Job Application",
    start: false,
    img: moby,
    mobile: true,
    show: true,

    a: "July 21, 2022",
    b: "Septermber 20 2022",
    tech: "Mobile: React Native JS",
  },
  {
    title: "Moblyze Web",
    type: "Find Job Application",
    start: false,
    img: mobyweb,
    mobile: false,
    show: true,

    a: "April 24, 2022",
    b: "July  20 2022",
    tech: "React JS",
  },
];

export const _handledproject2 = [
  {
    title: "Tool Rent",
    type: "Warehouse tool and Inventory System",
    start: false,
    img: tool,
  },
  {
    title: "Tool Rent",
    type: "Warehouse tool and Inventory System",
    start: false,
    img: tool1,
  },
];

export const itemMobile = [
  {
    img: pilon0,
    title: "mobile",
  },
  {
    img: pilon2,
    title: "mobile",
  },
  {
    img: pilon3,
    title: "mobile",
  },
  {
    img: survey1,
    title: "mobile",
  },
  {
    img: survey2,
    title: "mobile",
  },
  {
    img: survey3,
    title: "mobile",
  },
  {
    img: moby1,
    title: "mobile",
  },
  {
    img: moby2,
    title: "mobile",
  },
  {
    img: moby3,
    title: "mobile",
  },
  {
    img: wolly1,
    title: "mobile",
  },
  {
    img: wolly2,
    title: "mobile",
  },
  {
    img: wolly3,
    title: "mobile",
  },
  {
    img: carRent1,
    title: "mobile",
  },
  {
    img: carRent3,
    title: "mobile",
  },
  {
    img: carRent4,
    title: "mobile",
  },
  {
    img: carRent3,
    title: "mobile",
  },
];
export const itemDatasWeb = [
  {
    img: mobyweb,
    title: "moby web",
  },
  {
    img: web1,
    title: "moby web",
  },
  {
    img: web2,
    title: "moby web",
  },
  {
    img: web4,
    title: "moby web",
  },
  {
    img: web3,
    title: "moby web",
  },
  {
    img: web5,
    title: "moby web",
  },
  {
    img: web6,
    title: "moby web",
  },

  {
    img: tool,
    title: "sad",
  },
  {
    img: web7,
    title: "moby web",
  },
  {
    img: web10,
    title: "pilon",
  },
  {
    img: web11,
    title: "moby web",
  },

  {
    img: web12,
    title: "moby web",
  },
  {
    img: web13,
    title: "moby web",
  },
  {
    img: web14,
    title: "moby web",
  },

  {
    img: web15,
    title: "moby web",
  },
  {
    img: web16,
    title: "web 16",
  },
];
