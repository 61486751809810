import styled from "styled-components";
import styles from "../assets/styles";
import "./css/MobileTitle.css";
import { PhotoWeb } from "../../components/PhotoWeb";

export function ProjectCard({ title, image, color, star, types, mobile }) {
  return (
    <>
      {mobile ? (
        <BoxWrapper color={color}>
          <PhotoCard src={image} alt={title} />
          <TitleCard color={color}>
              <Title>{title}</Title>
              <Type>{types}</Type>
       
          </TitleCard>
        </BoxWrapper>
      ) : (
        <PhotoWeb img={image} title={title} context={types} color={color}/>
      )}
    </>
  );
}

const BoxWrapper = styled.article`
  height: auto;
  width: 246px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => props.color};
  border-radius: 12px;
  margin-bottom: 1.5%;
`;
const TitleCard = styled.section`
  background-color: ${(props) => props.color};
  min-height: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 2;
  gap: 10px;
    border-radius: 12px;

`;
const Title = styled.h4`
  font-family: ${styles.heavy};
  font-size: 1.25rem;
  color: ${styles.white};
  margin:0px 0 0 14px;
  float: left;
`;
const Type = styled.p`
  font-family: ${styles.medium};
  font-size: 1rem;
  color: ${styles.white};
  margin:0px 0 0 14px;
`;
const PhotoCard = styled.img`
  border-radius: 12px;
  z-index: 1;
  margin-top: 0px;
  width: 100%;
  height: 340px;
  object-fit: cover;
  object-position: 50% 0%;
  border-radius: 12px;
`;
